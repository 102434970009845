import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { StaticImage } from 'gatsby-plugin-image';
import { IoMdClose } from 'react-icons/io';
import DownloadMapButton from './DownloadMapButton';

export const SmallMap = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);

  const handleOpen = () => {
    setPopupOpen(true);
  };

  const handleClose = () => {
    setPopupOpen(false);
  };

  return (
    <>
      <div className="cursor-pointer" onClick={handleOpen}>
        <StaticImage
          height={800}
          src="../../images/other/Nuova planimetria Vicenza 2025-Tariffe per sito_page-0001 (1).jpg"
          quality={100}
          alt="mappa"
        />
      </div>
      <Modal
        show={isPopupOpen}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose}
      >
        <Modal.Header className="justify-content-end">
          <DownloadMapButton />
          <IoMdClose
            color="#324189"
            size={36}
            className="cursor-pointer"
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <StaticImage
            src="../../images/other/Nuova planimetria Vicenza 2025-Tariffe per sito_page-0001 (1).jpg"
            quality={100}
            alt="mappa"
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
