import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const DownloadMapButton = () => {
  return (
    <div className="cursor-pointer me-3">
      <a
        href="/api/download-map"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Scarica mappa parcheggi"
      >
        <StaticImage
          src="../../images/icons/icona download.png"
          quality={100}
          height={25}
          width={25}
          alt="mappa"
        />
      </a>
    </div>
  );
};

export default DownloadMapButton;
